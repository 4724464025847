import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import NavItem from './NavItem';

const useStyles = makeStyles(() => ({
  listItemText: {
    color: 'text.primary',
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase'
  }
}));

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List
    disablePadding
  >
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), []
    )}

  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        access={item.access}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const { items, pathname, title } = props;

  return (
    <List>
      <ListItem
        button
        onClick={handleClick}
        disableGutters
      >
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {renderNavItems({
          open,
          items,
          pathname
        })}
      </Collapse>
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
