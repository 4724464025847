import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
import moment from 'moment';
import DesktopDateTimePicker from '@material-ui/lab/DesktopDateTimePicker';
import { useSnackbar } from 'notistack';
import { cerateBanner, updateBanner, getBanners } from '../../../services/api.service';
import Scrollbar from '../../Scrollbar';
import FileDropzone from '../../FileDropzone';
import PencilAltIcon from '../../../icons/PencilAlt';

const BannersList = (props) => {
  const { banners, setOpen, open, currentTab, setBanners, ...other } = props;
  const [files, setFiles] = useState([]);
  const [bannerId, setBannerId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [show, setShow] = useState(false);

  const handleDrop = async ([file]) => {
    if (file) {
      setFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const addBanner = () => {
    if (files.length > 0) {
      cerateBanner(files[0]);
    }
  };

  const editBanner = (banner) => {
    setBannerId(banner.banner_id);
    setStartDate(banner.startDate);
    setEndDate(banner.endDate);
    setShow(true);
  };

  const updateMessage = () => {
    if (bannerId) {
      updateBanner(bannerId, startDate, endDate)
        .then(() => {
          getBanners().then((response) => {
            setBanners(response.data);
          });
          enqueueSnackbar('Bandeau a été modifié', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar('Un problème est survenue sur le serveur', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'error'
          });
        });
      setShow(false);
    }
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Banner
                </TableCell>
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  End Date
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(banners || []).map((banner) => (
                <TableRow
                  hover
                  key={banner.banner_id}
                >
                  <TableCell>
                    <img
                      style={{ width: '500px' }}
                      src={`data:image/png;base64,${banner.banner_content}`}
                      alt={banner.banner_id}
                    />
                  </TableCell>
                  <TableCell>
                    {moment(banner.startDate).format('DD/MM/yyyy k:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {moment(banner.endDate).format('DD/MM/yyyy k:mm:ss')}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => editBanner(banner)}>
                      <PencilAltIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleModalClose}
        open={open && (currentTab === 'banners')}
      >
        <>
          <Card>
            <CardHeader
              title="Create Banner"
            />
            <CardContent>
              <Grid
                item
              >
                <FileDropzone
                  accept=".jpg,.png,.pdf"
                  files={files}
                  onDrop={(event) => {
                    handleDrop(event);
                  }}
                  onRemove={(event) => {
                    handleRemove(event);
                  }}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={addBanner}>Create</Button>
            </CardActions>
          </Card>
        </>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setShow(false)}
        open={show}
      >
        <>
          <Card>
            <CardHeader
              title="Modifier un Bandeau"
            />
            <CardContent>
              <Box sx={{ mt: 5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 4
                  }}
                >
                  <DesktopDateTimePicker
                    label="Date de début"
                    onChange={(date) => setStartDate(date)}
                    renderInput={(inputProps) => (
                      <TextField
                        sx={{
                          minWidth: '200px'
                        }}
                        variant="outlined"
                        {...inputProps}
                        helperText=""
                      />
                    )}
                    value={startDate}
                  />
                  <DesktopDateTimePicker
                    label="Date de fin"
                    onChange={(date) => setEndDate(date)}
                    renderInput={(inputProps) => (
                      <TextField
                        sx={{
                          ml: 4,
                          minWidth: '200px'
                        }}
                        variant="outlined"
                        {...inputProps}
                        helperText=""
                      />
                    )}
                    value={endDate}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                onClick={updateMessage}
              >
                Valider
              </Button>
            </CardActions>
          </Card>
        </>
      </Dialog>
    </Card>
  );
};

BannersList.propTypes = {
  banners: PropTypes.array.isRequired,
  setBanners: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  currentTab: PropTypes.string
};

export default BannersList;
