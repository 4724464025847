import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import ModalProvider from './contexts/ModalContext';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MarketingTools from './pages/dashboard/MarketingTools';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit'))
);
const CustomerList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerList'))
);
const PdvAdd = Loadable(lazy(() => import('./pages/dashboard/PdvAdd')));
// Historique des transactions
const Transactions = Loadable(
  lazy(() => import('./pages/dashboard/Transactions'))
);
const CreditHistory = Loadable(
  lazy(() => import('./pages/dashboard/CreditHistory'))
);
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceDetails'))
);
const InvoiceList = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceList'))
);
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails'))
);
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProductCreate'))
);
const ProductList = Loadable(
  lazy(() => import('./pages/dashboard/ProductList'))
);

const PdvList = Loadable(
  lazy(() => import('./pages/dashboard/custom/PdvList'))
);
const PdvDetails = Loadable(
  lazy(() => import('./pages/dashboard/custom/PdvDetails'))
);

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import('./pages/dashboard/ProjectBrowse'))
);
const ProjectCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProjectCreate'))
);
const ProjectDetails = Loadable(
  lazy(() => import('./pages/dashboard/ProjectDetails'))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(
  lazy(() => import('./pages/dashboard/SocialProfile'))
);

// Claim page
const Claims = Loadable(lazy(() => import('./pages/dashboard/Claims')));

// const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));
// const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// Gestion pages
const Diary = Loadable(
  lazy(() => import('./pages/dashboard/Diary'))
);
const AccountDetailContent = Loadable(
  lazy(() => import('./pages/dashboard/AccountDetail'))
);
const AccountEditContent = Loadable(
  lazy(() => import('./pages/dashboard/AccountEdit'))
);
const AccountCreateContent = Loadable(
  lazy(() => import('./pages/dashboard/AccountCreate'))
);

// Marketing pages
const PromotionsCreate = Loadable(
  lazy(() => import('./pages/dashboard/PromotionCreate'))
);
const PromotionsList = Loadable(
  lazy(() => import('./pages/dashboard/PromotionsList'))
);
const PromotionsUpdate = Loadable(
  lazy(() => import('./pages/dashboard/PromotionUpdate'))
);
const Provionning = Loadable(
  lazy(() => import('./pages/dashboard/Provisionning'))
);

const Monitoring = Loadable(
  lazy(() => import('./pages/dashboard/Monitoring'))
);

const TransactionDetails = Loadable(
  lazy(() => import('./pages/dashboard/TransactionDetails'))
);

const CreditDetail = Loadable(
  lazy(() => import('./pages/dashboard/CreditDetail'))
);

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <ModalProvider>
          <DashboardLayout />
        </ModalProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: 'clients/pdv',
        children: [
          {
            path: '',
            element: <PdvList />
          },
          {
            path: ':pdvId',
            element: <PdvDetails />
          }
        ]
      },
      {
        path: 'gestion',
        children: [
          {
            path: 'journaux',
            element: <Diary />
          }
        ]
      },
      {
        path: 'marketing',
        children: [
          {
            path: 'promotions',
            element: <PromotionsList />
          },
          {
            path: 'promotions/new',
            element: <PromotionsCreate />
          },
          {
            path: 'promotions/:mode/:id',
            element: <PromotionsUpdate />
          },
          {
            path: 'products',
            children: [
              {
                path: '',
                element: <ProductList />
              },
              {
                path: 'new',
                element: <ProductCreate />
              }
            ]
          },
          {
            path: 'stocks',
            element: <MarketingTools />
          }
        ]
      },
      {
        path: 'serviceClient',
        children: [
          {
            path: 'monitoring',
            element: <Monitoring />
          },
          {
            path: 'monitoring/:code/:transId',
            element: <TransactionDetails />
          },
          {
            path: 'provisionning',
            element: <Provionning />
          },
          {
            path: 'provisionning/pdv/add',
            element: <PdvAdd />
          },
          {
            path: 'provisionning/pdv/history/:pdvId',
            children: [
              {
                path: '',
                element: <CreditHistory />
              },
              {
                path: ':checkoutHistoryId',
                element: <CreditDetail />
              }
            ]
          },
          {
            path: 'provisionning/pdv/show/:pdvIdent',
            children: [
              {
                path: '',
                element: <AccountDetailContent />
              }
            ]
          },
          {
            path: 'provisionning/pdv/edit/',
            element: <AccountEditContent />
          },
          {
            path: 'provisionning/account/create',
            element: <AccountCreateContent />
          },
          {
            path: 'transactions',
            children: [
              {
                path: 'pdv',
                element: <Transactions />
              }
            ]
          },
          {
            path: 'claims',
            children: [
              {
                path: '',
                element: <Claims />
              },
              {
                path: ':threadKey',
                element: <Claims />
              }
            ]
          }
        ]
      },
      // existing
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'analytics',
        element: <Analytics />
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            element: <Chat />
          },
          {
            path: 'new',
            element: <Chat />
          },
          {
            path: ':threadKey',
            element: <Chat />
          }
        ]
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <CustomerList />
          },
          {
            path: ':customerId',
            element: <CreditHistory />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            element: <InvoiceList />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />
          }
        ]
      },
      {
        path: 'kanban',
        element: <Kanban />
      },
      {
        path: 'mail',
        children: [
          {
            path: '',
            element: <Navigate
              to="/dashboard/mail/all"
              replace
            />
          },
          {
            path: 'label/:customLabel',
            element: <Mail />
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />
          },
          {
            path: ':systemLabel',
            element: <Mail />
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <OrderList />
          },
          {
            path: ':orderId',
            element: <OrderDetails />
          }
        ]
      },
      {
        path: 'finance',
        element: <Finance />
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <ProductList />
          },
          {
            path: 'new',
            element: <ProductCreate />
          }
        ]
      },
      {
        path: 'projects',
        children: [
          {
            path: 'browse',
            element: <ProjectBrowse />
          },
          {
            path: 'new',
            element: <ProjectCreate />
          },
          {
            path: ':projectId',
            element: <ProjectDetails />
          }
        ]
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeed />
          },
          {
            path: 'profile',
            element: <SocialProfile />
          }
        ]
      }
    ]
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '',
        element: <Navigate
          to="/docs/overview/welcome"
          replace
        />
      },
      {
        path: '*',
        element: <Docs />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <ModalProvider>
          <DashboardLayout />
        </ModalProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
