import { Avatar, Box, Button, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import CheckIcon from '../../../icons/Check';

const Modal8 = ({ content, data,
  onClose }) => (
    <Paper
      elevation={12}
      sx={{ position: 'relative' }}
    >
      <Box
        sx={{
          display: 'flex',
          pb: 2,
          pt: 3,
          px: 3
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
            color: 'success.main',
            mb: 2
          }}
        >
          <CheckIcon />
        </Avatar>
        <Box>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {content?.title}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            {content?.message}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5
        }}
      >
        <Button
          color="primary"
          sx={{ mr: 2 }}
          variant="outlined"
          onClick={() => onClose()}
        >
          Annuler
        </Button>
        <Button
          sx={{
            backgroundColor: 'primary',
            '&:hover': {
              backgroundColor: 'primary.dark'
            }
          }}
          variant="contained"
          onClick={() => {
            content?.onConfirm(data);
            onClose();
          }}
        >
          Confirmer
        </Button>
      </Box>
    </Paper>
);

Modal8.propTypes = {
  content: PropTypes.any,
  data: PropTypes.any,
  onClose: PropTypes.func
};

export default Modal8;
