import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  Autocomplete,
  TextField,
  Grid,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { addStock, getOperators } from '../../../services/api.service';
import FileDropzone from '../../FileDropzone';
import Scrollbar from '../../Scrollbar';
import countries from '../account/countries';

const StockNationalList = (props) => {
  const { stocks, setOpen, open, currentTab, ...other } = props;
  const [country, setCountry] = useState();
  const [operator, setOperator] = useState();
  const [operators, setOperators] = useState([]);
  const [value, setValue] = useState();
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleDrop = async ([file]) => {
    if (file) {
      setFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
  };

  useEffect(() => {
    if (country) {
      getOperators(country.value).then((response) => {
        setOperators(response.data);
      });
    }
  }, [country]);

  const sendStock = () => {
    if (operator && value && files.length > 0) {
      addStock(files[0], +value, operator.operator_id).then(() => {
        enqueueSnackbar('Le stock a été ajouté', {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          variant: 'success'
        });
        setOpen(false);
      }).catch(() => {
        enqueueSnackbar('Un problème dans le serveur est survenue', {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          },
          variant: 'error'
        });
      });
    }
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Operator
                </TableCell>
                <TableCell>
                  Value
                </TableCell>
                <TableCell>
                  Display Text
                </TableCell>
                <TableCell>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(stocks || []).map((stock) => (
                <TableRow
                  hover
                  key={stock.id}
                >
                  <TableCell>
                    {stock.operatorName}
                  </TableCell>
                  <TableCell>
                    {stock.value}
                  </TableCell>
                  <TableCell>
                    {stock.displayText}
                  </TableCell>
                  <TableCell>
                    {stock.count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleModalClose}
        open={open && (currentTab === 'stock')}
      >
        <>
          <DialogTitle>Import Stocks</DialogTitle>
          <DialogContent>
            <Grid
              item
            >
              <Autocomplete
                getOptionLabel={(option) => option.text}
                options={countries}
                value={country}
                name="country"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCountry(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    label="Sélectionner un pays"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              mt={5}
            >
              <Autocomplete
                getOptionLabel={(option) => option.operator_name}
                options={operators}
                name="operator"
                value={operator}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setOperator(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    label="Sélectionner Opérateur"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              mt={5}
            >
              <TextField
                label="Value"
                onChange={(e) => {
                  if (e.target.value) {
                    setValue(e.target.value);
                  } else {
                    setValue(0);
                  }
                }}
                type="number"
                value={value}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              mt={5}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                ml={5}
                sx={{ mx: 'auto', width: '50%' }}
              >
                Format du fichier : PINCODE;SERIAL;VALIDITY;VALIDITY;SERIAL
              </Typography>
            </Grid>
            <Grid
              item
              mt={5}
            >
              <FileDropzone
                accept=".jpg,.png,.pdf,.csv"
                files={files}
                onDrop={(event) => {
                  handleDrop(event);
                }}
                onRemove={(event) => {
                  handleRemove(event);
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={sendStock}
            >
              Push
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Card>
  );
};

StockNationalList.propTypes = {
  stocks: PropTypes.array.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  currentTab: PropTypes.string
};

export default StockNationalList;
