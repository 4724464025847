import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  IconButton,
  CardHeader,
  Dialog,
  CardContent,
  Button,
  CardActions,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import moment from 'moment';
import DesktopDateTimePicker from '@material-ui/lab/DesktopDateTimePicker';
import { useSnackbar } from 'notistack';
import { getAllBannerMessages, updateMessageBanner } from '../../../services/api.service';
import Scrollbar from '../../Scrollbar';
import PencilAltIcon from '../../../icons/PencilAlt';

const messageTypes = [
  {
    id: 1,
    severity: 'error'
  },
  {
    id: 2,
    severity: 'warning'
  },
  {
    id: 3,
    severity: 'info'
  },
  {
    id: 4,
    severity: 'success'
  }
];

const BannerMessageList = (props) => {
  const { ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [content, setContent] = useState('');
  const [messageType, setMessageType] = useState(0);
  const [messageId, setMessageId] = useState(0);

  const handleModalClose = () => {
    setOpen(false);
  };

  const editMessage = (message) => {
    setMessageId(message.banner_message_id);
    setStartDate(message.startDate);
    setEndDate(message.endDate);
    setContent(message.content);
    setMessageType(message.banner_message_type_id);
    setOpen(true);
  };

  const getAllMessages = () => {
    getAllBannerMessages().then((response) => {
      setMessages(response.data);
    }).catch(() => {
      setMessages([]);
    });
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  const updateMessage = () => {
    if (messageId) {
      updateMessageBanner(messageId, startDate, endDate, content, messageType)
        .then(() => {
          getAllMessages();
          enqueueSnackbar('Message a été modifié', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar('Un problème est survenue sur le serveur', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'error'
          });
        });
      handleModalClose();
    }
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  End Date
                </TableCell>
                <TableCell>
                  Message
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(messages || []).map((banner) => (
                <TableRow
                  hover
                  key={banner.banner_message_id}
                >
                  <TableCell>
                    <Alert
                      severity={messageTypes.find((e) => e.id === banner.banner_message_type_id).severity}
                    >
                      {banner.banner_message_type.label}
                    </Alert>
                  </TableCell>
                  <TableCell>
                    {moment(banner.startDate).format('DD/MM/yyyy k:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {moment(banner.endDate).format('DD/MM/yyyy k:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {banner.content}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => editMessage(banner)}>
                      <PencilAltIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleModalClose}
        open={open}
      >
        <>
          <Card>
            <CardHeader
              title="Modifier un message"
            />
            <CardContent>
              <Box sx={{ mt: 5 }}>
                <TextField
                  fullWidth
                  label="Message"
                  multiline
                  maxRows={3}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                  value={content}
                  variant="outlined"
                />
                <Box
                  sx={{
                    display: 'flex',
                    mt: 4
                  }}
                >
                  <TextField
                    fullWidth
                    label="Message Type"
                    onChange={(e) => {
                      setMessageType(e.target.value);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={messageType}
                    variant="outlined"
                  >
                    {messageTypes.map((elt) => (
                      <option
                        key={elt.id}
                        value={elt.id}
                      >
                        {elt.severity}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 4
                  }}
                >
                  <DesktopDateTimePicker
                    label="Date de début"
                    onChange={(date) => setStartDate(date)}
                    renderInput={(inputProps) => (
                      <TextField
                        sx={{
                          minWidth: '200px'
                        }}
                        variant="outlined"
                        {...inputProps}
                        helperText=""
                      />
                    )}
                    value={startDate}
                  />
                  <DesktopDateTimePicker
                    label="Date de fin"
                    onChange={(date) => setEndDate(date)}
                    renderInput={(inputProps) => (
                      <TextField
                        sx={{
                          ml: 4,
                          minWidth: '200px'
                        }}
                        variant="outlined"
                        {...inputProps}
                        helperText=""
                      />
                    )}
                    value={endDate}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                onClick={updateMessage}
              >
                Valider
              </Button>
            </CardActions>
          </Card>
        </>
      </Dialog>
    </Card>
  );
};

BannerMessageList.propTypes = {
};

export default BannerMessageList;
