import io from 'socket.io-client';
import { api, apiRecharge, pricesApi, utilitiesApi } from '../lib/api';

let eventListener;

// GLOBAL SSE EVENTS GETTERS
export const SSE_SUBSCRIPTIONS = {
  get getSocket() {
    return eventListener;
  }
};

export async function fetchPdvs() {
  return api.get('/pdvs');
}

export async function fetchWholesalers() {
  return api.get('/wholesalers');
}

export async function fetchPdvDetails(pdvId) {
  const id = pdvId?.match(/\d+/)[0];
  return api.get(`/pdv/${id}`);
}
export async function fetchWholesalerDetails(wholesalerId) {
  const id = wholesalerId?.match(/\d+/)[0];
  return api.get(`/wholesaler/${id}`);
}

export async function getComValueFromWs(serviceId, wsId) {
  return api.post('/wholesaler/getComValueFromWs/back', {
    serviceId,
    ws_id: wsId
  });
}

export async function showOperatorNameByServiceId(serviceId) {
  return api.post('/wholesaler/pdv/showPdvCommission', {
    serviceId
  });
}

export async function fetchCreditHisto(type, pdvId, checkoutId, startDate, endDate) {
  const data = {
    pdv_id: pdvId,
    checkout_id: checkoutId,
    begin: startDate,
    end: endDate,
    pagin: {
      limit: 25,
      offset: 0
    }
  };
  return api.post(`${type === 'PDV' ? 'pdv' : 'wholesaler'}/checkout/histo`, data);
}

export async function newCredit(type, pdvId, paymentOption, amount, files, comment) {
  const formData = new FormData();
  files.map((file) => formData.append('file', file));
  formData.append('id', pdvId);
  formData.append('paymentOption', paymentOption);
  formData.append('amount', amount);
  formData.append('comment', comment);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  if (type === 'PDV') {
    formData.append('wholesaler_id', '101');
    return api.post('pdv/checkout/credit', formData, config);
  }
  return api.post('wholesaler/checkout/credit', formData, config);
}

export async function cerateBanner(file) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return api.post('banners', formData, config);
}

export async function getBanners() {
  return api.get('banners');
}

export async function createPromotion(data) {
  return api.post('/pdv/promotions/new', data);
}

export async function getAllOeratorsByCountry(countryCode) {
  return api.post('/pdv/promotions/operators', { countryCode });
}

export async function getAllPromotions() {
  return api.get('/pdv/promotions/allPromotions');
}

export async function activePromotion(promotionId) {
  return api.post('/pdv/promotions/activePromotion', {
    promotion_id: promotionId
  });
}

export async function getPromotionById(promotionId) {
  return api.get(`/pdv/promotions/${promotionId}`);
}

export async function updatePromotion(data) {
  return api.post('/pdv/promotions/update', data);
}

export async function deletePromotion(promotionId) {
  return api.delete(`/pdv/promotions/${promotionId}`);
}

export async function activate(promotionId) {
  return api.patch(`/pdv/promotions/activate/${promotionId}`);
}

export async function desactivate(promotionId) {
  return api.patch(`/pdv/promotions/desactivate/${promotionId}`);
}

export async function saveBanner(promotionId, bannerFR, bannerES, bannerEN, bannerIT, start, end, content) {
  return api.post('/pdv/promotions/banner', {
    promotion_id: promotionId,
    banner_html_fr: bannerFR,
    banner_html_es: bannerES,
    banner_html_en: bannerEN,
    banner_html_it: bannerIT,
    start,
    end,
    content
  });
}

export async function getAllClaims() {
  return api.get('/pdv/claims/allClaims');
}

export async function getClaimDetails(claimId) {
  return api.get(`/pdv/claims/${claimId}`);
}

export async function markAsSeen(claimId) {
  return api.get(`/pdv/claims/markAsSeen/${claimId}`);
}

export async function reply(claimId, content, messageType) {
  return api.post('/pdv/claims/reply', {
    claim_id: claimId,
    message_text: content,
    message_type: messageType
  });
}

export async function deleteMessage(messageId) {
  return api.delete(`/pdv/claims/${messageId}`);
}

export async function updateResponsible(claimId, responsible) {
  return api.put(`/pdv/claims/${claimId}`, {
    responsible
  });
}

export async function closeClaim(claimId) {
  return api.patch(`/pdv/claims/${claimId}`);
}

export async function fetchPdvDetailsAllContent(pdvId) {
  return api.post('/pdv/allContent/show', {
    pdvId
  });
}

export async function getPdvFile(pdvId, wsId, fileName, fileType) {
  return api.post('/pdv/file/download', {
    pdv_id: pdvId,
    ws_id: wsId,
    file_name: fileName,
    file_type: fileType
  });
}

export async function getDiary(startDate, endDate, monthYear) {
  return api.post('/pdv/diary/getAllDiaries', {
    start: startDate,
    end: endDate,
    monthYear
  });
}

export async function getTransactionHistory(start, end, selectedColumnProductType, selectedColumn, searchValue) {
  return api.post('/pdv/transaction/transaction', {
    start,
    end,
    selectedColumnProductType,
    selectedColumn,
    searchValue
  });
}

export async function getJoxkoPinHistory(startDate, endDate, reference, number) {
  return api.post('/joxkopin/histo', {
    startDate,
    endDate,
    reference,
    number
  });
}

export async function getProofs(checkoutHistoryId) {
  return api.post('/wholesaler/documents', {
    checkout_history_id: checkoutHistoryId
  });
}

export async function getCheckoutHistoDetail(checkoutHistoryId) {
  return api.post('/wholesaler/checkout/detail', {
    checkout_history_id: checkoutHistoryId
  });
}

export async function fetchWholesalerDetailsAllContent(wsId) {
  return api.post('/wholesaler/allContent/show', {
    wsId
  });
}

/**
 * checkEmailExist
 *
 * @param {string} email
 *  */
export async function isExistingEmail(email, accountId) {
  return api.post('/account/isExistingEmail', {
    email,
    account_id: accountId
  });
}

/**
 * add pdv
 *
 */
export async function createAccount(
  type,
  firstname,
  lastname,
  entreprise,
  numTVA,
  numImmat,
  codeAPE,
  numStreet,
  street,
  cp,
  city,
  numMob,
  numFixe,
  email,
  comRechInt,
  tabNat,
  operators,
  countryIso2,
  isActive,
  isAgent,
  myListFileInfo,
  checkBtnDC,
  emailWS
) {
  return api.post('/account/create', {
    type,
    firstname,
    lastname,
    entreprise,
    numTVA,
    numImmat,
    codeAPE,
    numStreet,
    street,
    cp,
    city,
    numMob,
    numFixe,
    email,
    comRechInt,
    tabNat,
    operators,
    countryIso2,
    isActive,
    isAgent,
    myListFileInfo,
    checkBtnDC,
    emailWS
  });
}

export async function addNewProof(checkoutHistoryId, file, paymentOption, amount) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('checkout_history_id', checkoutHistoryId);
  formData.append('paymentOption', paymentOption);
  formData.append('amount', amount);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return api.post('/wholesaler/checkout/proof', formData, config);
}

export function deleteProof(checkoutHistoryId, proofId) {
  return api.post('/wholesaler/checkout/proof/delete', {
    checkout_history_id: checkoutHistoryId,
    proof_id: proofId
  });
}

export function getPrefixPhones(code, operatorId) {
  return api.post('/numerotationPlans', {
    code,
    operatorId
  });
}

export function getAllBannerMessages() {
  return api.get('/banner_messages');
}

export function updateMessageBanner(messageId, startDate, endDate, content, messageType) {
  return api.put(`/banner_messages/${messageId}`, {
    startDate,
    endDate,
    content,
    message_type_id: messageType
  });
}

export function updateBanner(bannerId, startDate, endDate) {
  return api.put(`/banners/${bannerId}`, {
    startDate,
    endDate
  });
}

export function deletePrefixPhone(prefixId) {
  return api.delete(`/numerotationPlans/${prefixId}`);
}

export function createPrefixPhone(code, operatorId, prefix) {
  return api.post('/numerotationPlans/create', {
    code,
    operator_id: operatorId,
    prefix
  });
}

export function getProviderSuggestion(providerName, phoneNumber) {
  return api.post('/numerotationPlans/providers', {
    provider_name: providerName,
    phone_number: phoneNumber
  });
}

/**
 * SSE up-to-date claims
 *
 */
export function onStartSocket() {
  // eslint-disable-next-line no-underscore-dangle
  eventListener = io('https://joxko.net', { secure: true });
  console.log(eventListener);
}

export function getTransactions(code, begin, end) {
  return apiRecharge.post('/test', {
    code,
    begin,
    end
  });
}

export function getTransaction(code, transId) {
  return apiRecharge.post('/test2', {
    code,
    transId
  });
}

export function changeNumber(number, code, transId) {
  return apiRecharge.post('/test3', {
    code,
    transId,
    number
  });
}

export function changeStatus(status, code, transId) {
  return apiRecharge.post('/test4', {
    code,
    transId,
    status
  });
}

export function saveChangingNumber(reference, newNumber, oldNumber) {
  return api.post('/pdv/transaction/changeNumber', {
    reference,
    new_number: newNumber,
    old_number: oldNumber
  });
}

/**
 * update pdv
 */
export async function updatePdv(
  accountId,
  addressId,
  pdvIdent,
  wsId,
  prenom,
  nom,
  entreprise,
  numTVA,
  numImmat,
  codeAPE,
  numStreet,
  street,
  cp,
  city,
  numMob,
  numFixe,
  email,
  comInt,
  comNat,
  country,
  status,
  isAgent,
  myListFileInfo,
  filesDeleted,
  cd,
  cdInfo,
  is_u2f_activated
) {
  return api.post('/wholesaler/updatePdv', {
    accountId,
    addressId,
    pdvIdent,
    wsId,
    prenom,
    nom,
    entreprise,
    numTVA,
    numImmat,
    codeAPE,
    numStreet,
    street,
    cp,
    city,
    numMob,
    numFixe,
    email,
    comInt,
    comNat,
    country,
    status,
    isAgent,
    myListFileInfo,
    filesDeleted,
    cd,
    cdInfo,
    is_u2f_activated
  });
}

export async function pdvUploadFile(myListFileInfo, listFiles, pdvId, wsId) {
  const formData = new FormData();
  listFiles.map((file, index) => {
    const blob = file.slice(0, file.size, file.type);
    formData.append(
      'file',
      new File([blob], `${myListFileInfo[index].name}.${file.name.split('.')[1]}`, {
        type: file.type
      })
    );
    return formData;
  });
  formData.append('pdv_id', pdvId);
  formData.append('ws_id', wsId);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return api.post('/wholesaler/uploadFile/back', formData, config);
}

export async function getCommissionType() {
  return api.post('/commissionType');
}

export function getGenearlProducts(code, operator, type, family, provider, active) {
  const params = new URLSearchParams();
  if (code) {
    params.append('code', code);
  }
  if (operator) {
    params.append('operator', operator?.operator_name);
  }
  if (type) {
    params.append('type', type);
  }
  if (family) {
    params.append('family', family);
  }
  if (provider) {
    params.append('provider', provider);
  }
  if (active) {
    params.append('active', active);
  }
  return pricesApi.get('/products', { params });
}

export function updateProduct(productId, data) {
  return pricesApi.put(`/products/${productId}`, data);
}

export function updateFaceValue(faceValueId, data) {
  return pricesApi.put(`/products/faceValue/${faceValueId}`, data);
}

export function updateFee(feeId, data) {
  return pricesApi.put(`/products/fee/${feeId}`, data);
}

export function getOperators(countryCode) {
  return pricesApi.get(`/operators/${countryCode}`);
}

export function createOperator(countryCode, operatorName, logo) {
  return pricesApi.post('/operators', {
    countryCode,
    operatorName,
    logo
  });
}

export function updateOperator(operatorId, name, logo) {
  return pricesApi.put(`/operators/${operatorId}`, {
    name,
    logo
  });
}

export function deleteOperator(operatorId) {
  return pricesApi.delete(`/operators/${operatorId}`);
}

export function getCurrencies() {
  return pricesApi.get('/currencies/');
}

export function createCurrency(code, name, rate) {
  return pricesApi.post('/currencies', {
    code,
    name,
    rate
  });
}

export function updateCurrency(id, rate) {
  return pricesApi.put(`/currencies/${id}`, {
    rate
  });
}

export function deleteCurrency(id) {
  return pricesApi.delete(`/currencies/${id}`);
}

export function toBeDeletedAlert() {
  return pricesApi.get('/alert/deleted');
}

export function toBeUpdatedAlert() {
  return pricesApi.get('/alert/updated');
}

export function createProduct(data) {
  return pricesApi.post('/products/', data);
}

export function deleteProduct(productId) {
  return pricesApi.delete(`/products/${productId}`);
}

export function automateOperator(operatorId) {
  return pricesApi.get(`/operators/automate/${operatorId}`);
}

export function disAutomateOperator(operatorId) {
  return pricesApi.get(`/operators/disautomate/${operatorId}`);
}

export function extractDingProducts() {
  return pricesApi.get('/ding/extract');
}

export function extractDingProductsTopupServer() {
  return pricesApi.get('/ding/extract/topupServer');
}

export function extractDtoneProducts() {
  return pricesApi.get('/dtone/extract/airtime');
}

export function extractReloadlyProducts() {
  return pricesApi.get('/reloadly/extract');
}

export function extractOrangeProducts() {
  return pricesApi.get('/orange/extract/products');
}

export function extractOrangeProductsTopupServer() {
  return pricesApi.get('/orange/extract/products/topupServer');
}

export function extractBalluwoProducts() {
  return pricesApi.get('/baluwo/extract');
}

export function updateGeneralProducts() {
  return pricesApi.get('/products/airtime/update');
}

export function extractGiftProducts() {
  return pricesApi.get('/reloadly/extract/gifts');
}

export function pushGifts() {
  return pricesApi.get('/products/gift/push');
}

export function getBrands() {
  return pricesApi.get('/brands');
}

export function makeFavourite(id) {
  return pricesApi.get(`/brands/makeFavourite/${id}`);
}

export function makeUnFavourite(id) {
  return pricesApi.get(`/brands/makeUnFavourite/${id}`);
}

export async function addStock(file, value, operatorId) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('value', value);
  formData.append('operator_id', operatorId);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return api.post('/stock/push_stock', formData, config);
}

export function getNationalStock() {
  return utilitiesApi.get('/stocks/');
}

export function importStockFile(operatorId, value, file) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return utilitiesApi.post(`/stocks?operator=${operatorId}&value=${value}`, formData, config);
}
