import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useDispatch } from '../../store';
import { SSE_SUBSCRIPTIONS, onStartSocket } from '../../services/api.service';
import { getClaims } from '../../slices/claim';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '20px',
  paddingBottom: '40px',
  minHeight: '100%',
  backgroundColor: '#f4f5f7',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '230px'
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClaims());
    if (!SSE_SUBSCRIPTIONS.getSocket) {
      onStartSocket();
      SSE_SUBSCRIPTIONS.getSocket.on('claims changed', () => {
        dispatch(getClaims());
      });
    } else {
      SSE_SUBSCRIPTIONS.getSocket.on('claims changed', () => {
        dispatch(getClaims());
      });
    }
  }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
