import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Button,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Dialog
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { createPrefixPhone, deletePrefixPhone, getOperators, getPrefixPhones, getProviderSuggestion } from '../../../services/api.service';
import countries from '../account/countries';
import Scrollbar from '../../Scrollbar';
import Trash from '../../../icons/Trash';

const NumerotationManagement = () => {
  const [operator, setOperator] = useState(null);
  const [operators, setOperators] = useState([]);
  const [country, setCountry] = useState(countries[0]);
  const [plans, setPlans] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dingResponse, setDingResponse] = useState('Aucun Operateur détécté');
  const [reloadlyResponse, setReloadlyResponse] = useState('Aucun Operateur détécté');
  const [orangeResponse, setOrangeResponse] = useState('Aucun Operateur détécté');
  const [dtoneResponse, setDtoneResponse] = useState('Aucun Operateur détécté');
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [operatorsForm, setOperatorsForm] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [prefix, setPrefix] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (country) {
      getOperators(country.value).then((response) => {
        setOperators(response.data);
      });
    }
  }, [country]);

  useEffect(() => {
    if (selectedCountry) {
      getOperators(country.value).then((response) => {
        setOperatorsForm(response.data);
      });
    }
  }, [selectedCountry]);

  const getPlans = () => {
    if (country) {
      if (operator) {
        getPrefixPhones(country.value, operator.operator_id).then((response) => {
          setPlans(response.data);
        });
      } else {
        getPrefixPhones(country.value, null).then((response) => {
          setPlans(response.data);
        });
      }
    } else {
      setPlans([]);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const createPrefix = () => {
    if (selectedCountry && selectedOperator && prefix) {
      createPrefixPhone(selectedCountry.value, selectedOperator.operator_id, prefix)
        .then(() => {
          enqueueSnackbar('Prefix a été ajouté', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'success'
          });
          handleModalClose();
        }).catch(() => {
          enqueueSnackbar('Un problème dans le serveur', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'error'
          });
        });
    }
  };

  const deletePrefix = (prefixId) => {
    if (prefixId) {
      deletePrefixPhone(prefixId)
        .then(() => {
          enqueueSnackbar('Prefix a été supprimé', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'success'
          });
          setPlans([...plans.filter((elt) => elt.prefix_phone_id !== prefixId)]);
        }).catch(() => {
          enqueueSnackbar('Un problème dans le serveur', {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom'
            },
            variant: 'error'
          });
        });
    }
  };

  const getProviderResponse = (provider) => {
    if (provider === 'orange' && phoneNumber) {
      getProviderSuggestion('orangeLink', phoneNumber).then((response) => {
        if (response.data.operator_name) {
          setOrangeResponse(response.data.operator_name);
        } else {
          setOrangeResponse('Aucun Operateur détécté');
        }
      }).catch(() => {
        setOrangeResponse('Aucun Operateur détécté');
      });
    }
    if (provider === 'ding') {
      getProviderSuggestion('ding', phoneNumber).then((response) => {
        if (response.data.operator_name) {
          setDingResponse(response.data.operator_name);
        } else {
          setDingResponse('Aucun Operateur détécté');
        }
      }).catch(() => {
        setDingResponse('Aucun Operateur détécté');
      });
    }
    if (provider === 'reloadly') {
      getProviderSuggestion('reloadly', phoneNumber).then((response) => {
        if (response.data.operator_name) {
          setReloadlyResponse(response.data.operator_name);
        } else {
          setReloadlyResponse('Aucun Operateur détécté');
        }
      }).catch(() => {
        setReloadlyResponse('Aucun Operateur détécté');
      });
    }
    if (provider === 'dtone') {
      getProviderSuggestion('dtone', phoneNumber).then((response) => {
        if (response.data.operator_name) {
          setDtoneResponse(response.data.operator_name);
        } else {
          setDtoneResponse('Aucun Operateur détécté');
        }
      }).catch(() => {
        setDtoneResponse('Aucun Operateur détécté');
      });
    }
  };
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <Card>
          <CardHeader title="Plans de numerotations" />
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Autocomplete
                  getOptionLabel={(option) => option.text}
                  options={countries}
                  value={country}
                  name="country"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      console.log(newValue);
                      setCountry(newValue);
                    } else {
                      setCountry(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Sélectionner un pays"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Autocomplete
                  getOptionLabel={(option) => option.operator_name}
                  options={operators}
                  name="operator"
                  value={operator}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setOperator(newValue);
                    } else {
                      setOperator(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Sélectionner Opérateur"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={getPlans}
                  >
                    Exécuter
                  </Button>
                </Box>
              </Box>
              <Box
                display="end"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => setOpen(true)}
                  >
                    Ajouter
                  </Button>
                </Box>
              </Box>
            </Box>
            <Scrollbar>
              <Box sx={{ minWidth: 500 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Operator
                      </TableCell>
                      <TableCell>
                        Prefix
                      </TableCell>
                      <TableCell align="right">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(plans || []).map((plan) => (
                      <TableRow
                        hover
                        key={plan.prefix_phone_id}
                      >
                        <TableCell>
                          {plan.prefix_phone_operator.operator_name}
                        </TableCell>
                        <TableCell>
                          {plan.prefix}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => deletePrefix(plan.prefix_phone_id)}>
                            <Trash color="red" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
      >
        <Card>
          <CardHeader title="Plans de numerotations" />
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <TextField
                  fullWidth
                  label="Numero de téléphone"
                  name="phone_number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  Ding
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  {dingResponse}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => getProviderResponse('ding')}
                  >
                    Exécuter
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  DtOne
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  {dtoneResponse}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => getProviderResponse('dtone')}
                  >
                    Exécuter
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  Reloadly
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  {reloadlyResponse}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => getProviderResponse('reloadly')}
                  >
                    Exécuter
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  OrangeLink
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                >
                  {orangeResponse}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
              >
                <Box
                  ml={1}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => getProviderResponse('orange')}
                  >
                    Exécuter
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleModalClose}
        open={open}
      >
        <>
          <Card>
            <CardHeader
              title="Ajouter un prefix"
            />
            <CardContent>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Autocomplete
                  getOptionLabel={(option) => option.text}
                  options={countries}
                  value={selectedCountry}
                  name="country"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedCountry(newValue);
                    } else {
                      setSelectedCountry(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Sélectionner un pays"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <Autocomplete
                  getOptionLabel={(option) => option.operator_name}
                  options={operatorsForm}
                  name="operator"
                  value={selectedOperator}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedOperator(newValue);
                    } else {
                      setSelectedOperator(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Sélectionner Opérateur"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  maxWidth: '100%',
                  width: 300
                }}
              >
                <TextField
                  fullWidth
                  label="Prefix"
                  variant="outlined"
                  value={prefix}
                  onChange={(event) => {
                    setPrefix(event.target.value);
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => createPrefix()}
                >
                  Ajouter
                </Button>
              </Box>
            </CardContent>
          </Card>
        </>
      </Dialog>
    </Grid>
  );
};

NumerotationManagement.propTypes = {
};

export default NumerotationManagement;
