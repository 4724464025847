const countries = [
  { value: 'AF', text: 'Afghanistan', phone: '93' },
  { value: 'AL', text: 'Albania', phone: '355' },
  { value: 'DZ', text: 'Algeria', phone: '213' },
  { value: 'AX', text: 'Alland Islands', phone: '358' },
  { value: 'AD', text: 'Andorra', phone: '376' },
  { value: 'AO', text: 'Angola', phone: '244' },
  { value: 'AI', text: 'Anguilla', phone: '1-264' },
  { value: 'AQ', text: 'Antarctica', phone: '672' },
  { value: 'AG', text: 'Antigua and Barbuda', phone: '1-268' },
  { value: 'AR', text: 'Argentina', phone: '54' },
  { value: 'AM', text: 'Armenia', phone: '374' },
  { value: 'AW', text: 'Aruba', phone: '297' },
  { value: 'AU', text: 'Australia', phone: '61' },
  { value: 'AT', text: 'Austria', phone: '43' },
  { value: 'AZ', text: 'Azerbaijan', phone: '994' },
  { value: 'BS', text: 'Bahamas', phone: '1-242' },
  { value: 'BH', text: 'Bahrain', phone: '973' },
  { value: 'BD', text: 'Bangladesh', phone: '880' },
  { value: 'BB', text: 'Barbados', phone: '1-246' },
  { value: 'BY', text: 'Belarus', phone: '375' },
  { value: 'BE', text: 'Belgium', phone: '32' },
  { value: 'BZ', text: 'Belize', phone: '501' },
  { value: 'BJ', text: 'Benin', phone: '229' },
  { value: 'BM', text: 'Bermuda', phone: '1-441' },
  { value: 'BT', text: 'Bhutan', phone: '975' },
  { value: 'BO', text: 'Bolivia', phone: '591' },
  { value: 'BA', text: 'Bosnia and Herzegovina', phone: '387' },
  { value: 'BW', text: 'Botswana', phone: '267' },
  { value: 'BV', text: 'Bouvet Island', phone: '47' },
  { value: 'BR', text: 'Brazil', phone: '55' },
  { value: 'IO', text: 'British Indian Ocean Territory', phone: '246' },
  { value: 'VG', text: 'British Virgin Islands', phone: '1-284' },
  { value: 'BN', text: 'Brunei Darussalam', phone: '673' },
  { value: 'BG', text: 'Bulgaria', phone: '359' },
  { value: 'BF', text: 'Burkina Faso', phone: '226' },
  { value: 'BI', text: 'Burundi', phone: '257' },
  { value: 'KH', text: 'Cambodia', phone: '855' },
  { value: 'CM', text: 'Cameroon', phone: '237' },
  { value: 'CA', text: 'Canada', phone: '1' },
  { value: 'CV', text: 'Cape Verde', phone: '238' },
  { value: 'KY', text: 'Cayman Islands', phone: '1-345' },
  { value: 'CF', text: 'Central African Republic', phone: '236' },
  { value: 'TD', text: 'Chad', phone: '235' },
  { value: 'CL', text: 'Chile', phone: '56' },
  { value: 'CN', text: 'China', phone: '86' },
  { value: 'CX', text: 'Christmas Island', phone: '61' },
  { value: 'CC', text: 'Cocos (Keeling) Islands', phone: '61' },
  { value: 'CO', text: 'Colombia', phone: '57' },
  { value: 'KM', text: 'Comoros', phone: '269' },
  { value: 'CD', text: 'Congo, Democratic Republic', phone: '243' },
  { value: 'CG', text: 'Congo', phone: '242' },
  { value: 'CK', text: 'Cook Islands', phone: '682' },
  { value: 'CR', text: 'Costa Rica', phone: '506' },
  { value: 'CI', text: "Cote d'Ivoire", phone: '225' },
  { value: 'HR', text: 'Croatia', phone: '385' },
  { value: 'CU', text: 'Cuba', phone: '53' },
  { value: 'CW', text: 'Curacao', phone: '599' },
  { value: 'CY', text: 'Cyprus', phone: '357' },
  { value: 'CZ', text: 'Czech Republic', phone: '420' },
  { value: 'DK', text: 'Denmark', phone: '45' },
  { value: 'DJ', text: 'Djibouti', phone: '253' },
  { value: 'DM', text: 'Dominica', phone: '1-767' },
  { value: 'DO', text: 'Dominican Republic', phone: '1-809' },
  { value: 'EC', text: 'Ecuador', phone: '593' },
  { value: 'EG', text: 'Egypt', phone: '20' },
  { value: 'SV', text: 'El Salvador', phone: '503' },
  { value: 'GQ', text: 'Equatorial Guinea', phone: '240' },
  { value: 'ER', text: 'Eritrea', phone: '291' },
  { value: 'EE', text: 'Estonia', phone: '372' },
  { value: 'ET', text: 'Ethiopia', phone: '251' },
  { value: 'FK', text: 'Falkland Islands (Malvinas)', phone: '500' },
  { value: 'FO', text: 'Faroe Islands', phone: '298' },
  { value: 'FJ', text: 'Fiji', phone: '679' },
  { value: 'FI', text: 'Finland', phone: '358' },
  { value: 'FR', text: 'France', phone: '33' },
  { value: 'GF', text: 'French Guiana', phone: '594' },
  { value: 'PF', text: 'French Polynesia', phone: '689' },
  { value: 'TF', text: 'French Southern Territories', phone: '262' },
  { value: 'GA', text: 'Gabon', phone: '241' },
  { value: 'GM', text: 'Gambia', phone: '220' },
  { value: 'GE', text: 'Georgia', phone: '995' },
  { value: 'DE', text: 'Germany', phone: '49' },
  { value: 'GH', text: 'Ghana', phone: '233' },
  { value: 'GI', text: 'Gibraltar', phone: '350' },
  { value: 'GR', text: 'Greece', phone: '30' },
  { value: 'GL', text: 'Greenland', phone: '299' },
  { value: 'GD', text: 'Grenada', phone: '1-473' },
  { value: 'GP', text: 'Guadeloupe', phone: '590' },
  { value: 'GU', text: 'Guam', phone: '1-671' },
  { value: 'GT', text: 'Guatemala', phone: '502' },
  { value: 'GG', text: 'Guernsey', phone: '44' },
  { value: 'GN', text: 'Guinea', phone: '224' },
  { value: 'GW', text: 'Guinea-Bissau', phone: '245' },
  { value: 'GY', text: 'Guyana', phone: '592' },
  { value: 'HT', text: 'Haiti', phone: '509' },
  { value: 'HM', text: 'Heard Island and McDonald Islands', phone: '672' },
  { value: 'VA', text: 'Holy See (Vatican City State)', phone: '379' },
  { value: 'HN', text: 'Honduras', phone: '504' },
  { value: 'HK', text: 'Hong Kong', phone: '852' },
  { value: 'HU', text: 'Hungary', phone: '36' },
  { value: 'IS', text: 'Iceland', phone: '354' },
  { value: 'IN', text: 'India', phone: '91' },
  { value: 'ID', text: 'Indonesia', phone: '62' },
  { value: 'IR', text: 'Iran, Islamic Republic of', phone: '98' },
  { value: 'IQ', text: 'Iraq', phone: '964' },
  { value: 'IE', text: 'Ireland', phone: '353' },
  { value: 'IM', text: 'Isle of Man', phone: '44' },
  { value: 'IL', text: 'Israel', phone: '972' },
  { value: 'IT', text: 'Italy', phone: '39' },
  { value: 'JM', text: 'Jamaica', phone: '1-876' },
  { value: 'JP', text: 'Japan', phone: '81' },
  { value: 'JE', text: 'Jersey', phone: '44' },
  { value: 'JO', text: 'Jordan', phone: '962' },
  { value: 'KZ', text: 'Kazakhstan', phone: '7' },
  { value: 'KE', text: 'Kenya', phone: '254' },
  { value: 'KI', text: 'Kiribati', phone: '686' },
  { value: 'KP', text: "Korea, Democratic People's Republic of", phone: '850' },
  { value: 'KR', text: 'Korea, Republic of', phone: '82' },
  { value: 'XK', text: 'Kosovo', phone: '383' },
  { value: 'KW', text: 'Kuwait', phone: '965' },
  { value: 'KG', text: 'Kyrgyzstan', phone: '996' },
  { value: 'LA', text: "Lao People's Democratic Republic", phone: '856' },
  { value: 'LV', text: 'Latvia', phone: '371' },
  { value: 'LB', text: 'Lebanon', phone: '961' },
  { value: 'LS', text: 'Lesotho', phone: '266' },
  { value: 'LR', text: 'Liberia', phone: '231' },
  { value: 'LY', text: 'Libya', phone: '218' },
  { value: 'LI', text: 'Liechtenstein', phone: '423' },
  { value: 'LT', text: 'Lithuania', phone: '370' },
  { value: 'LU', text: 'Luxembourg', phone: '352' },
  { value: 'MO', text: 'Macao', phone: '853' },
  { value: 'MK', text: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
  { value: 'MG', text: 'Madagascar', phone: '261' },
  { value: 'MW', text: 'Malawi', phone: '265' },
  { value: 'MY', text: 'Malaysia', phone: '60' },
  { value: 'MV', text: 'Maldives', phone: '960' },
  { value: 'ML', text: 'Mali', phone: '223' },
  { value: 'MT', text: 'Malta', phone: '356' },
  { value: 'MH', text: 'Marshall Islands', phone: '692' },
  { value: 'MQ', text: 'Martinique', phone: '596' },
  { value: 'MR', text: 'Mauritania', phone: '222' },
  { value: 'MU', text: 'Mauritius', phone: '230' },
  { value: 'YT', text: 'Mayotte', phone: '262' },
  { value: 'MX', text: 'Mexico', phone: '52' },
  { value: 'FM', text: 'Micronesia, Federated States of', phone: '691' },
  { value: 'MD', text: 'Moldova, Republic of', phone: '373' },
  { value: 'MC', text: 'Monaco', phone: '377' },
  { value: 'MN', text: 'Mongolia', phone: '976' },
  { value: 'ME', text: 'Montenegro', phone: '382' },
  { value: 'MS', text: 'Montserrat', phone: '1-664' },
  { value: 'MA', text: 'Morocco', phone: '212' },
  { value: 'MZ', text: 'Mozambique', phone: '258' },
  { value: 'MM', text: 'Myanmar', phone: '95' },
  { value: 'NA', text: 'Namibia', phone: '264' },
  { value: 'NR', text: 'Nauru', phone: '674' },
  { value: 'NP', text: 'Nepal', phone: '977' },
  { value: 'NL', text: 'Netherlands', phone: '31' },
  { value: 'NC', text: 'New Caledonia', phone: '687' },
  { value: 'NZ', text: 'New Zealand', phone: '64' },
  { value: 'NI', text: 'Nicaragua', phone: '505' },
  { value: 'NE', text: 'Niger', phone: '227' },
  { value: 'NG', text: 'Nigeria', phone: '234' },
  { value: 'NU', text: 'Niue', phone: '683' },
  { value: 'NF', text: 'Norfolk Island', phone: '672' },
  { value: 'MP', text: 'Northern Mariana Islands', phone: '1-670' },
  { value: 'NO', text: 'Norway', phone: '47' },
  { value: 'OM', text: 'Oman', phone: '968' },
  { value: 'PK', text: 'Pakistan', phone: '92' },
  { value: 'PW', text: 'Palau', phone: '680' },
  { value: 'PS', text: 'Palestine, State of', phone: '970' },
  { value: 'PA', text: 'Panama', phone: '507' },
  { value: 'PG', text: 'Papua New Guinea', phone: '675' },
  { value: 'PY', text: 'Paraguay', phone: '595' },
  { value: 'PE', text: 'Peru', phone: '51' },
  { value: 'PH', text: 'Philippines', phone: '63' },
  { value: 'PN', text: 'Pitcairn', phone: '870' },
  { value: 'PL', text: 'Poland', phone: '48' },
  { value: 'PT', text: 'Portugal', phone: '351' },
  { value: 'PR', text: 'Puerto Rico', phone: '1' },
  { value: 'QA', text: 'Qatar', phone: '974' },
  { value: 'RE', text: 'Reunion', phone: '262' },
  { value: 'RO', text: 'Romania', phone: '40' },
  { value: 'RU', text: 'Russian Federation', phone: '7' },
  { value: 'RW', text: 'Rwanda', phone: '250' },
  { value: 'BL', text: 'Saint Barthelemy', phone: '590' },
  { value: 'SH', text: 'Saint Helena', phone: '290' },
  { value: 'KN', text: 'Saint Kitts and Nevis', phone: '1-869' },
  { value: 'LC', text: 'Saint Lucia', phone: '1-758' },
  { value: 'MF', text: 'Saint Martin (French part)', phone: '590' },
  { value: 'PM', text: 'Saint Pierre and Miquelon', phone: '508' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { value: 'WS', text: 'Samoa', phone: '685' },
  { value: 'AS', text: 'American Samoa', phone: '1-684' },
  { value: 'SM', text: 'San Marino', phone: '378' },
  { value: 'ST', text: 'Sao Tome and Principe', phone: '239' },
  { value: 'SA', text: 'Saudi Arabia', phone: '966' },
  { value: 'SN', text: 'Senegal', phone: '221' },
  { value: 'RS', text: 'Serbia', phone: '381' },
  { value: 'SC', text: 'Seychelles', phone: '248' },
  { value: 'SL', text: 'Sierra Leone', phone: '232' },
  { value: 'SG', text: 'Singapore', phone: '65' },
  { value: 'SX', text: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { value: 'SK', text: 'Slovakia', phone: '421' },
  { value: 'SI', text: 'Slovenia', phone: '386' },
  { value: 'SB', text: 'Solomon Islands', phone: '677' },
  { value: 'SO', text: 'Somalia', phone: '252' },
  { value: 'ZA', text: 'South Africa', phone: '27' },
  { value: 'GS', text: 'South Georgia and the South Sandwich Islands', phone: '500' },
  { value: 'SS', text: 'South Sudan', phone: '211' },
  { value: 'ES', text: 'Spain', phone: '34' },
  { value: 'LK', text: 'Sri Lanka', phone: '94' },
  { value: 'SD', text: 'Sudan', phone: '249' },
  { value: 'SR', text: 'Suriname', phone: '597' },
  { value: 'SJ', text: 'Svalbard and Jan Mayen', phone: '47' },
  { value: 'SZ', text: 'Swaziland', phone: '268' },
  { value: 'SE', text: 'Sweden', phone: '46' },
  { value: 'CH', text: 'Switzerland', phone: '41' },
  { value: 'SY', text: 'Syrian Arab Republic', phone: '963' },
  { value: 'TW', text: 'Taiwan, Province of China', phone: '886' },
  { value: 'TJ', text: 'Tajikistan', phone: '992' },
  { value: 'TH', text: 'Thailand', phone: '66' },
  { value: 'TL', text: 'Timor-Leste', phone: '670' },
  { value: 'TG', text: 'Togo', phone: '228' },
  { value: 'TK', text: 'Tokelau', phone: '690' },
  { value: 'TO', text: 'Tonga', phone: '676' },
  { value: 'TT', text: 'Trinidad and Tobago', phone: '1-868' },
  { value: 'TN', text: 'Tunisia', phone: '216' },
  { value: 'TR', text: 'Turkey', phone: '90' },
  { value: 'TM', text: 'Turkmenistan', phone: '993' },
  { value: 'TC', text: 'Turks and Caicos Islands', phone: '1-649' },
  { value: 'TV', text: 'Tuvalu', phone: '688' },
  { value: 'VI', text: 'US Virgin Islands', phone: '1-340' },
  { value: 'UG', text: 'Uganda', phone: '256' },
  { value: 'UA', text: 'Ukraine', phone: '380' },
  { value: 'AE', text: 'United Arab Emirates', phone: '971' },
  { value: 'GB', text: 'United Kingdom', phone: '44' },
  { value: 'TZ', text: 'United Republic of Tanzania', phone: '255' },
  { value: 'US', text: 'United States', phone: '1' },
  { value: 'UY', text: 'Uruguay', phone: '598' },
  { value: 'UZ', text: 'Uzbekistan', phone: '998' },
  { value: 'VU', text: 'Vanuatu', phone: '678' },
  { value: 'VE', text: 'Venezuela', phone: '58' },
  { value: 'VN', text: 'Vietnam', phone: '84' },
  { value: 'WF', text: 'Wallis and Futuna', phone: '681' },
  { value: 'YE', text: 'Yemen', phone: '967' },
  { value: 'ZM', text: 'Zambia', phone: '260' },
  { value: 'ZW', text: 'Zimbabwe', phone: '263' }
];

export default countries;
